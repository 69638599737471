@tailwind base;
@tailwind components;
@tailwind utilities;

.font-cn_standard{
  font-family:DFKai-SB
}

.font-en_standard{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

img {
  pointer-events: none;
}

.font-en_uiMonospace{
  font-family: ui-monospace;
}

@font-face{
  font-family:"en_PermanentMarker";
  src:url("./fonts/en_PermanentMarker.ttf") format("truetype");
}

.font-en_PermanentMarker{
  font-family: en_PermanentMarker!important;
}

@font-face{
  font-family:"en_ShadowsIntoLight";
  src:url("./fonts/en_ShadowsIntoLight.ttf") format("truetype");
}

.font-en_ShadowsIntoLight{
  font-family: en_ShadowsIntoLight!important;
}

@font-face{
  font-family:"xmfont";
  src:url("./fonts/xmfont.ttf") format("truetype");
}

.font-xmfont{
  font-family: xmfont!important;
}

@font-face{
  font-family:"zhhk_HanYiXingKai";
  src:url("./fonts/zhhk_HanYiXingKai.ttf") format("truetype");
}

.font-zhhk_HanYiXingKai{
  font-family: zhhk_HanYiXingKai!important;
}


@font-face{
  font-family:"zhhk_Stamp";
  src:url("./fonts/zhhk_Stamp.ttf") format("truetype");
}

.font-zhhk_Stamp{
  font-family: zhhk_Stamp!important;
}

@font-face{
  font-family:"HanYiTianYuFeng";
  src:url("./fonts/HanYiTianYuFeng.ttf") format("truetype");
}

.font-HanYiTianYuFeng{
  font-family: HanYiTianYuFeng!important;
}

@font-face{
  font-family:"ChaoZiSheGuoFeng";
  src:url("./fonts/ChaoZiSheGuoFeng.ttf") format("truetype");
}

.font-ChaoZiSheGuoFeng{
  font-family: ChaoZiSheGuoFeng!important;
}

@font-face{
  font-family:"zhhk_HandPainted";
  src:url("./fonts/zhhk_HandPainted.ttf") format("truetype");
}

.font-zhhk_HandPainted{
  font-family: zhhk_HandPainted!important;
}

.slide {
  position: absolute;
  right: -100vw;
  /* overflow: hidden; */
  background: blue;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}

@-webkit-keyframes slide {
  100% { right: 0; }
}

@keyframes slide {
  100% { right: 0; }
}